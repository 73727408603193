import Axios from 'axios';
import { APIAssessment, Campaign, Assessment } from '../types';
const API_URL = process.env.REACT_APP_API;

class Assessments {
  async read(id: Assessment['id']): Promise<APIAssessment> {
    const response = await Axios.get(`${API_URL}/v1/assessments/${id}`);
    return response.data;
  }
  async readCampaignList(): Promise<Campaign[]> {
    const response = await Axios.get(`${API_URL}/v1/campaigns/`);
    return response.data;
  }
  async update(
    assessmentId: Assessment['id'],
    assessment: Pick<APIAssessment, 'answers' | 'comments' | 'completed'>
  ): Promise<void> {
    const response = await Axios.put(`${API_URL}/v1/assessments/${assessmentId}/evaluations`, assessment);
    return response.data;
  }
}

export default new Assessments();
