import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CommentIcon from '@material-ui/icons/Comment';
import UpdateIcon from '@material-ui/icons/Update';
import { RootState } from '../../store';
import { assessmentStatus } from '../../store/assessments';
import { Area } from '../../types';

const useStyles = makeStyles((theme) => ({
  stepIconCompleted: {
    fill: theme.palette.success.main,
  },
  stepIconInProgress: {
    fill: theme.palette.inProgress.main,
  },
  stepIconComment: {
    fill: theme.palette.primary.main,
  },
}));

interface AreaStatusIconProps {
  area?: Area;
}

function AreaStatusIcon(props: AreaStatusIconProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  const { area } = props;

  const statuses = useSelector((state: RootState) => assessmentStatus(state));

  const areaStatus = useMemo(() => (area?.id ? statuses.areas[area.id] : undefined), [statuses, area]);

  const title = useMemo(() => {
    if (!areaStatus) {
      return '';
    }

    return `${areaStatus?.answered}/${areaStatus?.total} ${t('common:answered_plural').toLowerCase()}`;
  }, [areaStatus, t]);

  if (!area) {
    return <CommentIcon className={classes.stepIconComment} />;
  }

  let icon = null;

  if (areaStatus) {
    if (areaStatus.answered === areaStatus.total) {
      icon = (
        <span title={title}>
          <CheckCircleIcon className={classes.stepIconCompleted} />
        </span>
      );
    } else {
      icon = (
        <span title={title}>
          <UpdateIcon className={classes.stepIconInProgress} />
        </span>
      );
    }
  }

  return <div title={title}>{icon}</div>;
}

export default AreaStatusIcon;
