import React, { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { styled } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { useBrandLogo } from '../../brand';
// import { checkToken as checkTokenAction } from '../../store/auth/actions';

import { RootState } from '../../store';
import { checkToken, readAuthInfo } from '../../store/auth';
import CenteredCardLayout from './AuthCard';
import LoaderButton from './LoaderButton';

const Root = styled('div')({
  marginTop: '5vh',
});

const Content = styled('div')({
  maxWidth: '400px',
});

const Form = styled(ListItem)({
  display: 'block',
}) as typeof ListItem;

const TextFieldWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(4, 0, 5),
}));

const CodeTextFiled = styled(TextField)(({ theme }) => ({
  '& input': {
    ...theme.typography.mono,
    letterSpacing: 2,
  },
  '& input:-webkit-autofill::first-line': {
    ...theme.typography.mono,
    fontSize: 16,
    letterSpacing: 2,
  },
  '& input:-webkit-autofill': {
    '-webkit-box-shadow': '0 0 0px 1000px white inset',
  },
}));

const ErrorAlert = styled(Alert)(({ theme }) => ({
  borderColor: theme.palette.grey[300],
  borderWidth: 1,
  borderStyle: 'solid',
  borderTop: 0,
  borderBottom: 0,
  borderRadius: 0,
}));

const ButtonsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  '&>div': {
    marginRight: 0,
  },
});

const validateToken = (token: string) => token.match(/^[A-Z0-9]{0,10}$/);

export default function Auth(): JSX.Element {
  const { t } = useTranslation();

  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const [token, setToken] = useState('');
  const [errorAlert, setErrorAlert] = useState(false);
  const { search } = useLocation();
  const queryToken = useMemo(() => new URLSearchParams(search).get('token'), [search]);
  const logo = useBrandLogo();

  // Check the token passed via URL query string on the component first mount
  useEffect(() => {
    if (queryToken && queryToken.length > 0) {
      dispatch(checkToken(queryToken));
    }
  }, [dispatch, queryToken]);

  useEffect(() => {
    dispatch(checkToken());
  }, [dispatch]);

  const handleTokenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;

    value = value.toUpperCase();

    if (validateToken(value)) {
      setToken(value);
    }
  };

  const handleSubmit = async () => {
    if (validateToken(token)) {
      const jwt = await dispatch(checkToken(token));

      if (!jwt) {
        setErrorAlert(true);
      }
    }
  };

  if (auth.jwt) {
    dispatch(readAuthInfo());

    return <Redirect to="/" />;
  }

  let errorAlertElement = null;

  if (errorAlert) {
    errorAlertElement = <ErrorAlert severity="error">{t('pollsAuthPage:tokenNotValid')}</ErrorAlert>;
  }

  return (
    <Root>
      <CenteredCardLayout imageSrc={logo} alert={errorAlertElement}>
        <Helmet>
          <title>{`${t('common:appFullName')}`}</title>
        </Helmet>
        <Content>
          <List>
            <Form>
              <Typography variant="h5" gutterBottom>
                {t('pollsAuthPage:title')}
              </Typography>
              <Typography variant="body1">{t('pollsAuthPage:description')}</Typography>
              <TextFieldWrapper>
                <CodeTextFiled
                  id="field-token"
                  label={t('pollsAuthPage:accessToken')}
                  autoFocus
                  value={token}
                  onChange={handleTokenChange}
                  fullWidth
                />
              </TextFieldWrapper>
              <ButtonsWrapper>
                <LoaderButton
                  onClick={handleSubmit}
                  color="primary"
                  variant="contained"
                  disableElevation
                  disabled={token.length < 7}
                  loading={auth.requests.checkToken.inProgress}
                >
                  {t('common:next')}
                </LoaderButton>
              </ButtonsWrapper>
            </Form>
          </List>
        </Content>
      </CenteredCardLayout>
    </Root>
  );
}
