import React, { useState, useEffect, useMemo } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import { useUpdateEffect } from '../../hooks';
import { RootState } from '../../store';
import { answerAssessmentQuestion, readAssessment, updateAssessment } from '../../store/assessments';
import { readCampaignList } from '../../store/campaigns';
import { APIAssessment, Assessment, Question } from '../../types';
import { canEditAssessment } from '../../utils';
import { AppLayout, CenteredLoader } from '../Layouts';
import Snackbar from '../Snackbar';
import AreasStepper from './AreasStepper';
import AreaStatusIcon from './AreaStatusIcon';
import AssessmentShortHelp from './AssessmentShortHelp';
import CloseModal from './CloseModal';
import FinishModal from './FinishModal';
import UserCard from './UserCard';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: theme.breakpoints.values.lg,
      width: theme.breakpoints.values.lg,
    },
  },
  sidebar: {
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(4),
    },
  },
  pageTitle: {
    marginBottom: theme.spacing(4),
  },
  alert: {
    color: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.dark,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: '16px',
  },
}));

function AssessmentComponent(): JSX.Element {
  const [dirty, setDirty] = useState(false);

  const [finishModalOpen, setFinishModalOpen] = useState(false);
  const [closeModalOpen, setCloseModalOpen] = useState(false);
  const [savedSnackbarOpen, setSavedSnackbarOpen] = useState(false);
  const [comments, setComments] = useState({
    feedback: '',
    indications: '',
  });
  const [activeStep, setActiveStep] = useState(0);

  const { assessmentId } = useParams() as { assessmentId: string };

  const assessment = useSelector((state: RootState) => state.assessments.assessments[assessmentId]);

  const campaigns = useSelector((state: RootState) => state.campaigns);
  const read = useSelector((state: RootState) => state.assessments.requests.read);
  const update = useSelector((state: RootState) => state.assessments.requests.update);
  const questions = useSelector((state: RootState) => state.assessments.questions);

  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const assessmentsRouteMatch = useRouteMatch('/assessments');

  const assessmentPeople = useSelector((state: RootState) => state.assessments.people);
  const areas = useSelector((state: RootState) => state.assessments.areas);

  const assessee = useMemo(() => (assessment ? assessmentPeople[assessment.assessee] : undefined), [
    assessment,
    assessmentPeople,
  ]);

  useEffect(() => {
    dispatch(readAssessment(assessmentId));
  }, [assessmentId, dispatch]);

  useUpdateEffect(() => {
    if (update.inProgress === false) {
      setSavedSnackbarOpen(true);
      setDirty(false);
    }
  }, [update.inProgress]);

  useUpdateEffect(() => {
    if (read.inProgress === false && assessment) {
      setComments(assessment.comments);
    }
  }, [read.inProgress]);

  const saveAssessment = async (completed = false) => {
    const answers: Assessment['answers'] = [];

    for (const [question_id, question] of Object.entries(questions)) {
      if (question.assessmentId === assessmentId) {
        if (question.answered) {
          answers.push({
            answered: question.answered,
            question_id: question_id,
            value: question.answer,
          });
        }
      }
    }

    const assessment = {
      answers,
      comments,
      completed,
    };

    await dispatch(updateAssessment(assessmentId, assessment));

    if (completed === true) {
      await dispatch(readCampaignList());
    }
  };

  const handleAnswerChange = (questionId: Question['id'], answer: Question['answer']) => {
    dispatch(answerAssessmentQuestion(questionId, answer));
    setDirty(true);
  };

  const handleCommentChange = (comments: Partial<APIAssessment['comments']>) => {
    setComments((oldComments) => ({ ...oldComments, ...comments }));
  };

  const handleBackToAssessmentsClick = () => {
    if (assessmentsRouteMatch) {
      history.push(assessmentsRouteMatch.path);
    }
  };

  const handleClose = () => {
    if (dirty) {
      setCloseModalOpen(true);
    } else {
      handleBackToAssessmentsClick();
    }
  };

  const handleSave = () => {
    saveAssessment(false);
  };

  const handleFinish = () => {
    setFinishModalOpen(true);
  };

  const handleFinishModalConfirm = async () => {
    await saveAssessment(true);
    handleBackToAssessmentsClick();
  };

  const handleCloseModalConfirm = () => {
    handleBackToAssessmentsClick();
  };

  if (!assessment) {
    return (
      <AppLayout>
        <CenteredLoader />
      </AppLayout>
    );
  }

  const campaignId = campaigns.byAssessment[assessment.id];
  const campaign = campaignId ? campaigns.byId[campaignId] : undefined;

  if (!campaign) {
    return (
      <AppLayout>
        <CenteredLoader />
      </AppLayout>
    );
  }

  let header = null;
  if (canEditAssessment(assessment.status)) {
    // Never show the help for self-evaluations
    header = <AssessmentShortHelp assessment={assessment} />;
  } else {
    header = <Paper className={classes.alert}>{t('assessmentPage:assessmentNotEditable')}</Paper>;
  }

  const sidebarAreaListItems = assessment.areas.map((areaId, i) => {
    const area = areas[areaId];

    if (!area) {
      return null;
    }

    return (
      <ListItem key={areaId} button selected={activeStep === i} onClick={() => setActiveStep(i)}>
        <ListItemIcon>
          <AreaStatusIcon area={area} />
        </ListItemIcon>
        <ListItemText primary={area.name} />
      </ListItem>
    );
  });

  sidebarAreaListItems.push(
    <ListItem
      key="indications"
      button
      selected={activeStep === assessment.areas.length}
      onClick={() => setActiveStep(assessment.areas.length)}
    >
      <ListItemIcon>
        <AreaStatusIcon />
      </ListItemIcon>
      <ListItemText primary={t('assessmentPage:indications')} />
    </ListItem>
  );

  return (
    <AppLayout>
      <Helmet>
        <title>{`${campaign.name} ${t('common:appFullName')}`}</title>
      </Helmet>
      <Grid container justify="space-around">
        <Grid className={classes.pageContent} item xs={12}>
          <Typography variant="h4" className={classes.pageTitle}>
            {`${campaign.name}`}
          </Typography>
        </Grid>
        <Grid item container className={classes.pageContent}>
          <Grid className={classes.sidebar} item md={5} xs={12}>
            {assessee ? <UserCard user={assessee} /> : null}
            <Box>
              <Paper elevation={1}>
                <List component="nav" dense aria-label="main mailbox folders">
                  {sidebarAreaListItems}
                </List>
              </Paper>
            </Box>
          </Grid>
          <Grid item md={7} xs={12}>
            <div>
              {header}
              <AreasStepper
                activeStep={activeStep}
                assessment={assessment}
                // areas={areas}
                // questions={questions}
                // subareas={subareas}
                comments={comments}
                campaign={campaign}
                onAnswerChange={handleAnswerChange}
                onCommentChange={handleCommentChange}
                onBackToAssessmentsClick={handleBackToAssessmentsClick}
                onClose={handleClose}
                onChangeActiveStep={setActiveStep}
                onSave={handleSave}
                onFinish={handleFinish}
                // update={update}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <FinishModal
        open={finishModalOpen}
        onConfirm={handleFinishModalConfirm}
        onClose={() => setFinishModalOpen(false)}
        saving={update.inProgress}
      />
      <CloseModal open={closeModalOpen} onConfirm={handleCloseModalConfirm} onClose={() => setCloseModalOpen(false)} />
      <Snackbar
        open={savedSnackbarOpen}
        onClose={() => setSavedSnackbarOpen(false)}
        message={update.error ? t('assessmentPage:savedSnackbar:error') : t('assessmentPage:savedSnackbar:message')}
        variant={update.error ? 'error' : null}
      />
    </AppLayout>
  );
}

export default AssessmentComponent;
