import React from 'react';
import PropTypes from 'prop-types';
import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { styled } from '@material-ui/core/styles';

const StyledSpinner = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.grey[400],
})) as typeof CircularProgress;

interface LoaderButtonProps extends ButtonProps {
  className?: string | undefined;
  loading: boolean;
  disabled?: boolean;
}

/**
 * A button with a loading spinner inside of it.
 * Set `loading` true to show the spinner, it is hidden otherwise.
 * The button will inherit the other LoaderButton props.
 */
const LoaderButton = function LoaderButton(props: LoaderButtonProps): JSX.Element {
  const { className, loading, children, ...otherProps } = props;

  return (
    <Button className={className} {...otherProps} disabled={otherProps.disabled || loading}>
      {!loading ? children : <StyledSpinner thickness={5} size={16} />}
    </Button>
  );
};

LoaderButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  classes: PropTypes.object,
  loading: PropTypes.bool,
};

export default LoaderButton;
