import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { setup as apiSetup } from './api';
import App from './components/App/App';
import i18n from './i18n';

import './index.css';
import store from './store';
import { theme } from './theme';

i18n();

apiSetup();

ReactDOM.render(
  <Provider store={store}>
    <Fragment>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </MuiThemeProvider>
    </Fragment>
  </Provider>,
  document.getElementById('root')
);

// Logs process.env variables when not on prod environment
if ((process.env.NODE_ENV as string) !== 'Production') {
  // eslint-disable-next-line
  console.log(process.env);
}
