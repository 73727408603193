import React from 'react';
import { Redirect, Switch } from 'react-router';

import { Router, Route } from 'react-router-dom';

import history from '../../history';
import Assessment from '../Assessment';
import Assessments from '../Assessments';
import Auth from '../Auth/Auth';
import AuthGuard from '../Auth/AuthGuard';
import GDPRModal from './GDPRModal';

const FallbackRoute = () => <Redirect to="/assessments" />;

export default function App(): JSX.Element {
  return (
    <>
      <Router history={history}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/assessments" />
          </Route>
          <Route
            exact
            path={'/assessments'}
            render={() => (
              <AuthGuard>
                <Assessments />
              </AuthGuard>
            )}
          />
          <Route
            path={'/assessments/:assessmentId'}
            render={() => (
              <AuthGuard>
                <Assessment />
              </AuthGuard>
            )}
          />
          <Route path={'/auth'} component={Auth} />
          <Route component={FallbackRoute} />
        </Switch>
        <GDPRModal />
      </Router>
    </>
  );
}
