import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { styled } from '@material-ui/core/styles';
import { RootState } from '../../store';
import { logout } from '../../store/auth';

const BrandAvatar = styled(Avatar)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
}));

export default function SimpleMenu(): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const authInfo = useSelector((state: RootState) => state.auth.authInfo);

  const firstNameInitials = authInfo.first_name ? authInfo.first_name[0] : '';
  const lastNameInitials = authInfo.last_name ? authInfo.last_name[0] : '';

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.target as HTMLDivElement);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    history.push('/');
    dispatch(logout());
  };

  return (
    <div>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenuClick}
        color="inherit"
      >
        <BrandAvatar>{`${firstNameInitials}${lastNameInitials}`}</BrandAvatar>
      </IconButton>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleClose} disabled>
          {`${authInfo.first_name} ${authInfo.last_name}`}
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
}
