import { RequestError, RequestState } from '@top-solution/utils';

export type NumberRequestState = Omit<RequestState, 'lastUpdate'> & {
  lastUpdate: number | null;
};

export function mutateRequestState(state: NumberRequestState): void {
  state.inProgress = true;
}

export function mutateSuccessState(state: NumberRequestState): void {
  state.inProgress = false;
  state.error = null;
  state.lastUpdate = new Date().valueOf();
}

export function mutateErrorState(state: NumberRequestState, error: NumberRequestState['error']): void {
  state.inProgress = false;
  state.error = error;
}

export function requestState(): NumberRequestState {
  return {
    inProgress: false,
    error: null,
    lastUpdate: null,
  };
}

export interface RequestFailureAction {
  error: RequestError;
}
