import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import AssessmentsAPI from '../api/Assessments';
import { APIAssessment, Campaign } from '../types';
import {
  mutateRequestState,
  mutateSuccessState,
  RequestFailureAction,
  mutateErrorState,
  requestState,
} from './toolkitUtils';
import { AppThunk } from '.';

const initialState = {
  byId: {} as Record<Campaign['id'], Campaign>,
  byAssessment: {} as Record<APIAssessment['id'], Campaign['id']>,
  byPoll: {} as Record<Campaign['poll']['id'], Campaign['id']>,
  polls: {} as Record<Campaign['poll']['id'], Campaign['poll']>,
  requests: {
    readList: requestState(),
  },
};

export type CampaignState = typeof initialState;

interface ReadCampaignListSuccessAction {
  campaigns: Campaign[];
}

const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    readCampaignListRequest(state) {
      mutateRequestState(state.requests.readList);
    },
    readCampaignListSuccess(state, action: PayloadAction<ReadCampaignListSuccessAction>) {
      const { campaigns } = action.payload;
      for (let i = 0; i < campaigns.length; i++) {
        const campaign = campaigns[i];
        const campaignAssessments = campaign?.assessments;

        if (campaign && campaignAssessments) {
          const assessments = [];

          for (let i = 0; i < campaignAssessments.length; i++) {
            const assessment = campaignAssessments[i];
            if (assessment) {
              const id = assessment.id;
              assessments.push(id);
              state.byAssessment[id] = campaign.id;
              state.byPoll[id] = campaign.poll.id;
              state.polls[campaign.poll.id] = campaign.poll;
            }
          }

          state.byId[campaign.id] = campaign;
        }
      }
      mutateSuccessState(state.requests.readList);
    },
    readCampaignListFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.readList, action.payload.error);
    },
  },
});

export const campaignsActions = campaignsSlice.actions;

export default campaignsSlice.reducer;

export const readCampaignList = (): AppThunk => async (dispatch) => {
  dispatch(campaignsActions.readCampaignListRequest());
  let campaigns;

  try {
    campaigns = await AssessmentsAPI.readCampaignList();
  } catch (err) {
    dispatch(campaignsActions.readCampaignListFailure(err.toString()));
  }

  if (campaigns) {
    dispatch(campaignsActions.readCampaignListSuccess({ campaigns }));
  }
};
