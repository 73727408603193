import { ThunkAction, configureStore, Action } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import assessments from './assessments';
import auth from './auth';
import campaigns from './campaigns';

const rootReducer = combineReducers({
  auth,
  assessments,
  campaigns,
});

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;
