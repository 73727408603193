import Axios from 'axios';
import { AuthInfo } from '../types';
const API_URL = process.env.REACT_APP_API;

class AuthApi {
  constructor() {
    this.checkToken = this.checkToken.bind(this);
  }

  async checkToken(token: string): Promise<string> {
    const response = await Axios.post(`${API_URL}/v1/assessors/signin`, {
      token,
    });
    return response.data.token;
  }

  async readAuthInfo(): Promise<AuthInfo> {
    const response = await Axios.get(`${API_URL}/v1/assessors/me`);
    return response.data;
  }
}

export default new AuthApi();
