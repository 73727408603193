import * as history from 'history';

const browserHistory = history.createBrowserHistory();
// Utils to debug RR4 issues
if ((process.env.NODE_ENV as string) !== 'Production') {
  browserHistory.listen((location, action) => {
    // eslint-disable-next-line
    console.log(
      `The current URL is ${ location.pathname }${ location.search }${ location.hash }`,
    );
    // eslint-disable-next-line
    console.log(`The last navigation action was ${action}`, JSON.stringify(browserHistory, null,2));
  });
}

export default browserHistory;
