import React from 'react';
import { styled } from '@material-ui/core/styles';
import MDIRating from '@material-ui/lab/Rating';

const StyledRating = styled(MDIRating)(({ theme }) => ({
  marginRight: theme.spacing(3),
}));

export default function Rating({ value }: { value: number }): JSX.Element {
  return <StyledRating size="small" name="read-only" value={value} readOnly />;
}
