import React, { useEffect, useState } from 'react';

import AssessmentHelp from './environments/default/AssessmentHelp';
import defaultLogo from './environments/default/logo.svg';
import { AssessmentHelpProps } from './types';

const defaultBrand = {
  palette: {
    primary: '#1e1e65',
    secondary: '#72bae5',
  },
};

const env = process.env as Record<string, string | undefined>;

export const brand = {
  palette: {
    primary: env.REACT_APP_BRAND_PALETTE_PRIMARY || defaultBrand.palette.primary,
    secondary: env.REACT_APP_BRAND_PALETTE_SECONDARY || defaultBrand.palette.secondary,
  },
};

const environmentPromise = async (file: string): Promise<unknown> => {
  let module;
  try {
    module = (await import(`./environments/${env.REACT_APP_BRAND}/${file}`))?.default;
  } catch (error) {
    return Promise.resolve(undefined);
  }

  return module;
};

export const useBrandLogo = (): string => {
  const [logo, setLogo] = useState(defaultLogo);

  useEffect(() => {
    (async () => {
      const brandLogoUrl = await environmentPromise('logo.svg');

      if (brandLogoUrl) {
        setLogo(brandLogoUrl as string);
      }
    })();
  }, []);

  return logo;
};

export const HelpComponent = (props: AssessmentHelpProps): JSX.Element => {
  if (env.REACT_APP_BRAND) {
    let Component: React.LazyExoticComponent<(props: AssessmentHelpProps) => JSX.Element> | null = null;

    Component = React.lazy(() =>
      // eslint-disable-next-line no-console
      import(`./environments/${env.REACT_APP_BRAND}/AssessmentHelp`).catch((error) => console.error(error))
    );

    if (Component) {
      return <Component {...props} />;
    }
  }

  return <AssessmentHelp />;
};
