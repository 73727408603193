import React from 'react';
import ReactMarkdown from 'react-markdown';

import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import { RootState } from '../../store';
import { Question, Campaign } from '../../types';
import { capitalize } from '../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  radios: {
    flex: '0 0 auto',
  },
  question: {
    marginLeft: 0,
    marginRight: 0,
  },
  questionTextP: {
    fontSize: `${theme.typography.fontSize}px`,
    paddingRight: theme.spacing(2),
    marginTop: 0,
  },
  questionNameP: {
    marginTop: 0,
  },
  name: {
    flex: '2 1 auto',
  },
  radioGroup: {
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
  fieldset: {
    width: '100%',
  },
  ratingText: {
    width: '30px',
    textAlign: 'center',
  },
  notAnsweringButton: {
    width: '120px',
  },
}));

interface QuestionComponentProps {
  onChange: (answer: number) => void;
  questionId: Question['id'];
  evaluation_scale: Campaign['evaluation_scale'];
  disabled: boolean;
}

function QuestionComponent(props: QuestionComponentProps): JSX.Element | null {
  const classes = useStyles();
  const { evaluation_scale, questionId, onChange, disabled } = props;

  const max = evaluation_scale?.max || 5;
  const min = evaluation_scale?.min || 0;

  const question = useSelector((state: RootState) => state.assessments.questions[questionId]);

  // const notAnswering = question.answered === true && (question.answer === null || typeof question.answer === 'undefined');

  if (!question) {
    return null;
  }

  const name = (
    <Typography variant="h6" className={classes.questionNameP}>
      {capitalize(question?.name)}
    </Typography>
  );

  const description = question.description ? (
    <Typography className={classes.questionTextP} component="div">
      <ReactMarkdown source={question.description} />
    </Typography>
  ) : null;

  return (
    <Paper className={classes.root} elevation={1}>
      <Box p={2} pb={0} display="flex">
        <div className={classes.name}>{name}</div>
        <div className={classes.radios}>
          <FormControl component="fieldset" className={classes.fieldset}>
            <Box display="flex" bgcolor="background.paper" alignItems="center">
              {/* <Box mr={ 2 }>
                <Button
                  variant={ notAnswering? 'outlined' : undefined }
                  color={ notAnswering? 'primary' : undefined }
                  onClick={ () => onChange(null) }
                  fontWeight={ notAnswering? 700 : 300 }
                  size="small"
                  className={ classes.notAnsweringButton }
                  disabled={ disabled }
                >
                  { 'Non rispondo' }
                </Button>
              </Box> */}
              <Rating
                name={`question-rating-${question.id}`}
                value={(question.answer || 0) - min + 1}
                max={max - min + 1}
                onChange={(_e, value) => onChange(Number(value) + min - 1)}
                size="large"
                disabled={disabled}
              />
            </Box>
          </FormControl>
        </div>
      </Box>
      <Box p={2} pt={0}>
        <div>{description}</div>
      </Box>
    </Paper>
  );
}

export default QuestionComponent;
