import React, { Fragment, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { RootState } from '../../store';
import { readCampaignList } from '../../store/campaigns';
import { Assessment } from '../../types';
import { AppLayout } from '../Layouts';
import AssessmentsList from './AssessmentsList';
import AssessmentsTable from './AssessmentsTable';

const PageContent = styled(Grid)(({ theme }) => ({
  maxWidth: theme.breakpoints.values.sm,
  width: '100%',
})) as typeof Grid;

const StyledAssessmentsTable = styled(AssessmentsTable)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
})) as typeof AssessmentsTable;

const StyledAssessmentsList = styled(AssessmentsList)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
})) as typeof AssessmentsList;

export default function AssessmentsComponent(): JSX.Element | null {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const campaigns = useSelector((state: RootState) => Object.values(state.campaigns.byId));

  const handleAssessmentClick = useCallback(
    (id: Assessment['id']) => {
      history.push(`${url}/${id}`);
    },
    [history, url]
  );
  useEffect(() => {
    dispatch(readCampaignList());
  }, [dispatch]);

  if (!campaigns) {
    return null;
  }

  let assessmentsElement = null;

  if (campaigns?.length) {
    assessmentsElement = (
      <Fragment>
        <StyledAssessmentsTable onAssessmentClick={handleAssessmentClick} />
        <StyledAssessmentsList onAssessmentClick={handleAssessmentClick} />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Helmet>
        <title>{`${t('assessmentsPage:pageHtmlTitle')} ${t('common:appShortName')}`}</title>
      </Helmet>
      <AppLayout>
        <Grid container justify="center">
          <PageContent item>
            <Typography variant="h4" gutterBottom>
              {t('assessmentsPage:pageTitle')}
            </Typography>
            {assessmentsElement}
          </PageContent>
        </Grid>
      </AppLayout>
    </Fragment>
  );
}
