const it = {
  common: {
    appFullName: 'Top Solution 測 HAKA',
    appShortName: '測 HAKA',
    yes: 'Sì',
    no: 'No',
    cancel: 'Annulla',
    submit: 'Invia',
    save: 'Salva',
    update: 'Aggiorna',
    retry: 'Riprova',
    ok: 'OK',
    close: 'Chiudi',
    next: 'Prosegui',
    back: 'Indietro',
    finish: 'Termina',
    search: 'Cerca',
    email: 'Email',
    password: 'Password',
    rowsPerPage: 'Righe',
    displayedRows: '{{from}}-{{to}} di {{count}}',
    answered_plural: 'Risposte',
  },
  menu: {
    companySwitch: 'Cambia azienda',
    logout: 'Esci',
    addCredits: 'Ricarica',
    peopleManagement: 'Gestione personale',
    campaigns: 'Campagne',
    credits: 'Crediti',
  },
  errors: {
    unexpected: "D'oh!",
    required: 'Questo campo è obbligatorio',
    invalidFormat: 'Formato non valido',
    invalidEmail: "Inserisci un'email valida",
    invalidPhone: 'Inserisci un numero di telefono valido',
    invalidVat: 'Deve contenere almeno 4 caratteri',
  },
  assessmentsPage: {
    pageTitle: 'Scegli un questionario',
    pageHtmlTitle: 'Questionari',
    tablePollNameColumnTitle: 'Nome questionario',
    tableStatusColumnTitle: 'Stato',
    tableDueDateColumnTitle: 'Scadenza',
    selfEvaluation: 'Autovalutazione',
    expiresOn: 'Scade il ',
    expiredOn: 'Scaduto il ',
  },
  login: {
    title: 'Autenticazione',
    description: 'Clicca per essere reindirizzato alla pagina di autenticazione.',
    submit: 'Entra',
  },
  companiesManagement: {
    emptyList: 'Non hai ancora associato aziende al tuo account',
    select: "Seleziona l'azienda su cui operare",
    add: 'Nuova azienda',
    companyData: 'Anagrafica azienda',
    companyDataDescription:
      "Verifica attentamente tutti i campi, questi dati verranno utilizzati per l'emissione delle fatture.",
    deleteConfirmTitle: 'Sei sicuro di voler rimuovere {{name}}?',
    deleteConfirmDescription:
      'Questa operazione non è reversibile e comporta la perdita di tutto lo storico dei dati associati.',
  },
  company: {
    name: 'Ragione sociale',
    email: 'Indirizzo email',
    pec: 'Indirizzo PEC',
    pecHelperText: 'Indirizzo Posta Elettronica Certificata',
    phone: 'Numero di telefono',
    address: 'Indirizzo sede legale',
    addressHelperText: "Inserisci l'indirizzo e seleziona una opzione dai suggerimenti",
    vatNumber: 'Partita IVA',
    sdiNumber: 'Codice Destinatario',
    sdiNumberHelperText: 'Codice destinario SdI per fatturazione elettronica',
    fiscalCode: 'Codice fiscale',
    fiscalCodeHelperText: 'Lascia vuoto se uguale alla Partita IVA',
    errorMissingEmailOrPhone: 'Compila il campo "Indirizzo email" oppure "Numero di telefono"',
    errorMissingPECorSDI: 'Compila il campo "Indirizzo PEC" oppure "Codice Destinatario"',
    size: 'Numero dipendenti',
    market: 'Settore',
    referralCode: 'Codice promozione',
    referralCodeHelperText: 'Se possiedi un codice promozionale inseriscilo qui',
  },
  companySize: {
    xs: 'Fino a 9',
    sm: 'Da 10 a 19',
    md: 'Da 20 a 49',
    lg: 'Da 50 a 250',
    xl: 'Oltre 250',
  },
  companyMarket: {
    automotive: 'Automotive',
    finance: 'Finanza',
    ict: 'ICT',
    industry: 'Industria manifatturiera',
    healthcare: 'Sanità',
    public: 'Settore pubblico',
    services: 'Servizi',
  },
  peopleManagement: {
    title: 'Gestione del personale',
    emptyFilteredList: 'La ricerca non ha prodotto alcun risultato',
    emptyList: 'Non hai ancora aggiunto nessun dipendente',
    deleteConfirmTitle: 'Sei sicuro di voler rimuovere {{lastName}} {{firstName}}?',
    deleteConfirmDescription:
      'Questa operazione non è reversibile e comporta la perdita di tutto lo storico dei dati associati.',
    addDialogTitle: 'Nuovo dipendente',
    addDialogDescription: 'Inserisci i dati del dipendente, tutti i campi sono obbligatori:',
  },
  person: {
    firstName: 'Nome',
    lastName: 'Cognome',
    email: 'Email',
    position: 'Ruolo',
    branch: 'Funzione',
  },
  position: {
    all: 'Tutti',
    worker: 'Operaio',
    apprentice: 'Apprendista',
    clerk: 'Impiegato',
    supervisor: 'Impiegato gestionale',
    manager: 'Quadro',
    executive: 'Executive',
  },
  branch: {
    all: 'Tutte',
    sales: 'Commerciale',
    purchasing: 'Acquisti',
    marketing: 'Marketing',
    administration: 'Amministrazione',
    hr: 'Risorse umane',
    rd: 'Ricerca & sviluppo',
    finance: 'Finance',
    quality: 'Qualità',
    operations: 'Operations',
  },
  poll: {
    employeesPerformance: 'Performance dipendenti',
    executivesPerformance: 'Performance executive',
    360: 'Valutazione 360°',
  },
  campaignsManagement: {
    emptyList: 'Non hai ancora creato nessuna campagna per il questionario "{{name}}"',
    addDialogTitle: 'Nuova campagna',
    addDialogDescription: 'Inserisci i dati delle nuova campagna, tutti i campi sono obbligatori:',
    deleteAssessmentConfirmTitle: 'Sei sicuro di voler rimuovere la valutazione?',
    deleteAssessmentDescription: 'Questa operazione non è reversibile.',
  },
  campaign: {
    name: 'Nome',
    description: 'Descrizione',
    startDate: 'Inizio',
    endDate: 'Fine',
    fromTo: 'Dal {{startDate}} al {{endDate}}',
    status: 'Stato',
  },
  campaignStatus: {
    all: 'Tutto',
    draft: 'Bozza',
    ready: 'Pronto',
    inProgress: 'In corso',
    closed: 'Chiuso',
  },
  assessmentManagement: {
    addDialogTitle: 'Nuova valutazione',
    addDialogDescription: 'Seleziona valutato e valutatore:',
    emptyFilteredList: 'La ricerca non ha prodotto alcun risultato',
    emptyList: 'Non hai ancora aggiunto nessuna valutazione',
  },
  assessment: {
    assessor: 'Valutatore',
    assessee: 'Valutato',
    status: 'Stato',
    selfAssessment: 'Autovalutazione',
  },
  assessmentStatus: {
    all: 'Tutto',
    draft: 'Bozza',
    ready: 'Pronto',
    inProgress: 'In corso',
    closed: 'Chiuso',
    overdue: 'Scaduto',
  },
  assessmentPage: {
    thanksForCompiling: 'Grazie per aver compilato il questionario',
    completedPercentage: 'Questionario completo al: {{percentage}}%',
    backToAssessments: 'Torna alla lista dei questionari',
    answerAllQuestions: 'Rispondi a tutte le domande prima di teminare il questionario',
    textFieldsHaveErrors: 'Ricontrolla i testi inseriti prima di teminare il questionario',
    textMax500Chars: 'Il testo non deve contenere più di 500 caratteri',
    indications: 'Eventuali commenti integrativi',
    assessmentNotEditable: 'Il questionario non è modificabile',
    assessmentStatusNotValid: 'Il questionario non è modificabile in qunto è in stato "{{status}}"',
    finishModal: {
      title: 'Vuoi davvero terminare il questionario?',
      text: 'Terminare il questionario vuol dire non poter modificare piú le risposte in un secondo momento.',
      confirm: 'Termina',
      close: 'Annulla',
    },
    closeModal: {
      title: 'Vuoi davvero tornare alla lista dei questionari senza salvare?',
      text: "Tutte le modifiche apportate a partire dall'ultimo salvataggio saranno perse.",
      confirm: 'Chiudi',
      close: 'Annulla',
    },
    savedSnackbar: {
      message: 'Questionario salvato',
      error: 'Si è verificato un errore durante il salvataggio del questionario',
    },
    shortHelp: {
      beforeText:
        'Nel valutare ogni singolo aspetto legato alle competenze vi chiediamo di attenervi a queste indicazioni:',
      stars: {
        1: 'NON IN LINEA con le aspettative/richieste',
        2: 'PARZIALMENTE in linea con le aspettative/richieste',
        3: 'IN LINEA con le aspettative/richieste',
        4: 'TALVOLTA sopra le aspettative/richieste',
        5: 'COSTANTEMENTE sopra le aspettative/richieste',
      },
      afterText: 'Per maggiori informazioni, consulta la',
      selfEvaluationNote:
        'Stai compilando la tua autovalutazione. Le descrizioni dei comportamenti/competenze sono da leggere in prima persona.',
    },
  },
  pollsAuthPage: {
    title: 'Accedi ai tuoi questionari',
    description:
      'Scrivi qui il codice di accesso che ti è stato inviato via e-mail per procedere alla compilazione dei tuoi questionari:',
    accessToken: 'Codice di accesso',
    tokenNotValid: 'Il codice di accesso non è corretto',
  },
};

export default it;
