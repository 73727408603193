import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { RootState } from '../../store';
import { CampaignAssessment } from '../../types';
import AssessmentStatusLabel from '../AssessmentStatusLabel';

const AssessmentListItemText = styled(ListItemText)({
  paddingRight: 0,
});

const AssessmentListItemTextContent = styled('span')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
});

const SecondaryText = styled('span')(({ theme }) => ({
  display: 'flex',
  fontSize: theme.typography.fontSize,
}));

const CampaignListSubHeader = styled(ListSubheader)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  lineHeight: theme.spacing(3),
}));

const CampaignDivider = styled(Divider)({
  '&:last-child': {
    display: 'none',
  },
});

const AssessmentName = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

interface AssessmentsListProps {
  className?: string | undefined;
  onAssessmentClick: (assessmentId: CampaignAssessment['id']) => void;
}

function AssessmentsList(props: AssessmentsListProps): JSX.Element {
  const { className, onAssessmentClick } = props;
  const { t } = useTranslation();

  const { polls, byId: campaigns } = useSelector((state: RootState) => state.campaigns);

  const pollSectionElements = [];

  for (const [pollId, poll] of Object.entries(polls)) {
    const campaignTableElements = [];

    for (const [, campaign] of Object.entries(campaigns)) {
      if (campaign.poll.id === pollId) {
        const assessmentRows = campaign.assessments.map((assessment) => {
          const { id, assessor, assessee } = assessment;
          let assessmentName = `${assessee?.first_name} ${assessee?.last_name}`;

          if (assessee.id === assessor.id) {
            assessmentName = t('assessmentsPage:selfEvaluation');
          }

          return (
            <ListItem button key={id} onClick={() => onAssessmentClick(assessment.id)}>
              <AssessmentListItemText
                primary={
                  <AssessmentListItemTextContent>
                    <span>{`${assessmentName}`.toLocaleUpperCase()}</span>
                    <SecondaryText>
                      <AssessmentStatusLabel value={assessment.status} />
                    </SecondaryText>
                  </AssessmentListItemTextContent>
                }
              />
            </ListItem>
          );
        });

        let expiresOnText = null;

        if (moment(campaign.end_at).isBefore(moment())) {
          expiresOnText = `${t('assessmentsPage:expiresOn')} ${moment(campaign.end_at).format('L')}`;
        } else {
          expiresOnText = `${t('assessmentsPage:expiredOn')} ${moment(campaign.end_at).format('L')}`;
        }

        campaignTableElements.push(
          <List key={campaign.id}>
            <CampaignListSubHeader>
              <Typography variant="subtitle2">{`${campaign.name}`}</Typography>
              <Typography variant="caption" gutterBottom>
                {expiresOnText}
              </Typography>
            </CampaignListSubHeader>
            {assessmentRows}
            <CampaignDivider />
          </List>
        );
      }
    }

    if (campaignTableElements.length > 0) {
      pollSectionElements.push(
        <Fragment key={pollId}>
          <AssessmentName variant="h6" gutterBottom>
            {`${t(poll.name)}`}
          </AssessmentName>
          <Paper elevation={1}>{campaignTableElements}</Paper>
        </Fragment>
      );
    }
  }

  return <div className={className}>{pollSectionElements}</div>;
}

export default AssessmentsList;
