import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { CampaignAssessment } from '../../types';
import AssessmentStatusLabel from '../AssessmentStatusLabel';

const NameCell = styled(TableCell)(({ theme }) => ({
  paddingLeft: theme.spacing(5),
})) as typeof TableCell;

const AssessmentNameButton = styled(Button)({
  width: '100%',
  '& .MuiButton-label': {
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
}) as typeof Button;

interface AssessmentsTableRowProps {
  assessment: CampaignAssessment;
  onAssessmentClick: (assessmentId: CampaignAssessment['id']) => void;
}

const AssessmentsTableRow = function AssessmentsTableRow(props: AssessmentsTableRowProps): JSX.Element {
  const { assessment, onAssessmentClick } = props;
  const { t } = useTranslation();

  const { id, assessor, assessee } = assessment;

  const handleNameClick = useCallback(() => {
    onAssessmentClick(id);
  }, [id, onAssessmentClick]);

  let assessmentName = `${assessee?.first_name} ${assessee?.last_name}`;

  if (assessee.id === assessor.id) {
    assessmentName = t('assessmentsPage:selfEvaluation');
  }

  return (
    <TableRow>
      <NameCell scope="assessment">
        <AssessmentNameButton onClick={handleNameClick}>{`${assessmentName}`}</AssessmentNameButton>
      </NameCell>
      <TableCell>
        <AssessmentStatusLabel value={assessment.status} />
      </TableCell>
    </TableRow>
  );
};

export default AssessmentsTableRow;
