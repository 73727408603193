import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import { styled } from '@material-ui/core/styles';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { useBrandLogo } from '../../brand';
import AvatarMenuElement from './AvatarMenuElement';

const StyledAppBar = styled(AppBar)({
  backgroundColor: '#fff',
});

const Title = styled(Typography)({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
});

const AppName = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(),
}));

const CompanyLogo = styled('div')(({ theme }) => ({
  height: Number(theme.mixins.toolbar.height) - theme.spacing(2),
  width: Number(theme.mixins.toolbar.height) - theme.spacing(2),
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
}));

export default function ButtonAppBar(): JSX.Element {
  const logo = useBrandLogo();

  return (
    <StyledAppBar color="transparent">
      <Toolbar>
        <Title variant="h6">
          <CompanyLogo style={{ backgroundImage: `url(${logo})` }} />
          <AppName>{'HAKA'}</AppName>
        </Title>
        <AvatarMenuElement />
      </Toolbar>
    </StyledAppBar>
  );
}
