import { styled } from '@material-ui/core/styles';

const CenteredLayout = styled('div')(({ theme }) => ({
  alignItems: 'flex-start',
  justifyContent: 'center',
  display: 'flex',
  minHeight: '100vh',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(3),
  },
}));

export default CenteredLayout;
