import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import AssessmentsAPI from '../api/Assessments';

import { APIArea, APIAssessment, Area, Assessment, Subarea, Person, Question, APISubarea } from '../types';
import {
  mutateRequestState,
  mutateSuccessState,
  RequestFailureAction,
  mutateErrorState,
  requestState,
} from './toolkitUtils';
import { AppThunk, RootState } from '.';

const initialState = {
  assessments: {} as Record<APIAssessment['id'], Assessment>,
  areas: {} as Record<APIArea['id'], Area>,
  subareas: {} as Record<APISubarea['id'], Subarea>,
  questions: {} as Record<Question['id'], Question>,
  people: {} as Record<Person['id'], Person>,
  requests: {
    read: requestState(),
    update: requestState(),
  },
};

export type AssessmentState = typeof initialState;

interface ReadAssessmentSuccessAction {
  assessment: APIAssessment;
}

interface AnswerQuestionAction {
  questionId: Question['id'];
  answer: Question['answer'];
}

const assessmentsSlice = createSlice({
  name: 'assessments',
  initialState,
  reducers: {
    readAssessmentRequest(state) {
      mutateRequestState(state.requests.read);
    },
    readAssessmentSuccess(state, action: PayloadAction<ReadAssessmentSuccessAction>) {
      const { assessment } = action.payload;
      const areas = [];
      const areasQuestions: Question['id'][] = [];
      const areasSubareas: Subarea['id'][] = [];

      for (let i = 0; i < assessment.areas.length; i++) {
        const area = assessment.areas[i];

        if (area) {
          const stateArea: Area = {
            ...area,
            questions: [],
            subareas: [],
          };

          const areaSubareas = [];
          const areaQuestions = [];

          if (area.questions) {
            for (let j = 0; j < area.questions.length; j++) {
              const question = area.questions[j];

              if (question) {
                state.questions[question.id] = {
                  ...question,
                  assessmentId: assessment.id,
                  areaId: area.id,
                };

                areaQuestions.push(question.id);
                areasQuestions.push(question.id);
              }
            }

            stateArea.questions = areaQuestions;
          }

          if (area.subareas) {
            // debugger;
            for (let j = 0; j < area.subareas.length; j++) {
              const subarea = area.subareas[j];

              if (subarea) {
                const stateSubarea: Subarea = {
                  ...subarea,
                  assessmentId: assessment.id,
                  areaId: area.id,
                  questions: [],
                };

                const subareaQuestions = [];

                for (let k = 0; k < subarea.questions.length; k++) {
                  const question = subarea.questions[k];

                  if (question) {
                    state.questions[question.id] = {
                      ...question,
                      assessmentId: assessment.id,
                      subareaId: subarea.id,
                      areaId: area.id,
                    };

                    subareaQuestions.push(question.id);
                    areasQuestions.push(question.id);
                  }
                }

                stateSubarea.questions = subareaQuestions;
                areaSubareas.push(subarea.id);
                state.subareas[subarea.id] = stateSubarea;
              }
            }

            stateArea.subareas = areaSubareas;
          }

          areas.push(stateArea);
          state.areas[area.id] = stateArea;
        }
      }

      state.assessments[assessment.id] = {
        ...assessment,
        assessee: assessment.assessee.id,
        assessor: assessment.assessor.id,
        areas: areas.map((area) => area.id),
        questions: areasQuestions,
        subareas: areasSubareas,
      };
      mutateSuccessState(state.requests.read);
    },
    readAssessmentFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.read, action.payload.error);
    },
    updateAssessmentRequest(state) {
      mutateRequestState(state.requests.update);
    },
    updateAssessmentSuccess(state) {
      mutateSuccessState(state.requests.update);
    },
    updateAssessmentFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.update, action.payload.error);
    },
    answerQuestion(state, action: PayloadAction<AnswerQuestionAction>) {
      if (action.payload.answer) {
        const question = state.questions[action.payload.questionId];
        if (question) {
          question.answer = action.payload.answer;
          question.answered = true;
        }
      }
    },
  },
});

export const assessmentsActions = assessmentsSlice.actions;

export default assessmentsSlice.reducer;

export const readAssessment = (assessmentId: APIAssessment['id']): AppThunk => async (dispatch) => {
  dispatch(assessmentsActions.readAssessmentRequest());
  let assessment;
  try {
    assessment = await AssessmentsAPI.read(assessmentId);
  } catch (err) {
    dispatch(assessmentsActions.readAssessmentFailure(err.toString()));
  }

  if (assessment) {
    dispatch(assessmentsActions.readAssessmentSuccess({ assessment }));
  }
};

export const updateAssessment = (
  assessmentId: APIAssessment['id'],
  assessment: Pick<APIAssessment, 'answers' | 'comments' | 'completed'>
): AppThunk => async (dispatch) => {
  dispatch(assessmentsActions.updateAssessmentRequest());
  try {
    /*const newAssessment = */ await AssessmentsAPI.update(assessmentId, assessment);
    dispatch(assessmentsActions.updateAssessmentSuccess());
  } catch (err) {
    dispatch(assessmentsActions.updateAssessmentFailure(err.toString()));
  }
};

export const answerAssessmentQuestion = (questionId: Question['id'], answer: Question['answer']): AppThunk => async (
  dispatch
) => {
  dispatch(assessmentsActions.answerQuestion({ questionId, answer }));
};

// export function useAssessment(assessmentId: APIAssessment['id']): APIAssessment {
//   const assessments = useSelector((state: RootState) => state.assessments.assessments);
//   const areas = useSelector((state: RootState) => state.assessments.areas);
//   const questions = useSelector((state: RootState) => state.assessments.questions);
//   const subareas = useSelector((state: RootState) => state.assessments.subareas);
//   const people = useSelector((state: RootState) => state.assessments.people);

//   return useMemo(() => {
//     const denormalizedAssessment = assessments[assessmentId];
//     return {
//       ...denormalizedAssessment,
//       areas: denormalizedAssessment.areas.map((areaId) => areas[areaId]),
//       questions: denormalizedAssessment.questions.map((questionId) => questions[questionId]),
//       subareas: denormalizedAssessment.subareas.map((subareaId) => subareas[subareaId]),
//       assessee: people[denormalizedAssessment.assessee],
//       assessor: people[denormalizedAssessment.assessor],
//     };
//   }, [areas, assessmentId, assessments, people, questions, subareas]);
// }

const selectSelf = (state: RootState) => state.assessments;

export type AssessmentStatus = {
  areas: Record<Area['id'], { area: Area; answered: number; total: number }>;
  answered: number;
  total: number;
};

export const assessmentStatus = createSelector(selectSelf, (state) => {
  const areas = Object.values(state.areas);
  const subareas = state.subareas;
  const questions = state.questions;

  const result: AssessmentStatus = {
    areas: {},
    answered: 0,
    total: 0,
  };

  for (let i = 0; i < areas.length; i++) {
    const area = areas[i];
    let answered = 0;
    let total = 0;

    if (area) {
      if (area.questions) {
        total += area.questions.length;

        for (let i = 0; i < area.questions.length; i++) {
          const questionId = area.questions[i];
          if (questionId && questions[questionId]) {
            if (questions[questionId]?.answered) {
              answered++;
            }
          }
        }
      }

      if (area.subareas) {
        for (let i = 0; i < area.subareas.length; i++) {
          const subareaId = area.subareas[i];

          if (subareaId) {
            const subarea = subareas[subareaId];
            if (subarea) {
              total += subarea.questions.length;

              for (let j = 0; j < subarea.questions.length; j++) {
                const questionId = subarea.questions[j];
                if (questionId && questions[questionId]) {
                  if (questions[questionId]?.answered) {
                    answered++;
                  }
                }
              }
            }
          }
        }
      }

      result.areas[area.id] = {
        area,
        answered,
        total,
      };

      result.answered += answered;
      result.total += total;
    }
  }

  return result;
});
