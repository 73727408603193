import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import orange from '@material-ui/core/colors/orange';
import { createMuiTheme } from '@material-ui/core/styles';
import { brand } from './brand';

import 'typeface-open-sans';

declare module '@material-ui/core/styles/createPalette' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Palette {
    inProgress: Palette['primary'];
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface PaletteOptions {
    inProgress: PaletteOptions['primary'];
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Typography {
    mono: typeof theme.typography.caption;
  }
}

declare module '@material-ui/core/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    mono: true;
  }
}

export const theme = createMuiTheme({
  // Uncomment for v5...
  // components: {
  //   MuiTypography: {
  //     variants: [
  //       {
  //         props: { variant: 'mono' },
  //         style: {
  //           ...defaultTheme.typography.body1,
  //           fontFamily: '"PT Mono", "Courier New", Courier, monospace',
  //         },
  //       },
  //     ],
  //   },
  // },
  props: {
    MuiTextField: {
      variant: 'outlined',
    },
    MuiPaper: {
      variant: 'outlined',
    },
    MuiMenu: {
      PaperProps: {
        variant: 'elevation',
      },
    },
  },
  typography: {
    fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
    mono: {
      fontFamily: '"PT Mono", "Courier New", Courier, monospace',
    },
  },
  palette: {
    background: {
      default: grey[50],
    },
    primary: {
      main: brand.palette.primary,
    },
    secondary: {
      main: brand.palette.secondary,
    },
    success: {
      main: green['600'],
      light: green['200'],
      dark: green['800'],
      contrastText: '#fff',
    },
    warning: {
      main: orange['600'],
      light: orange['200'],
      dark: orange['800'],
      contrastText: '#fff',
    },
    inProgress: {
      main: orange['600'],
      light: orange['200'],
      dark: orange['800'],
      contrastText: '#fff',
    },
  },
  mixins: {
    toolbar: {
      height: 64,
    },
  },
});

declare module '@material-ui/core/styles/createTypography' {
  interface Typography {
    mono: React.CSSProperties;
  }

  // allow configuration using `createMuiTheme`
  interface TypographyOptions {
    mono?: React.CSSProperties;
  }
}

declare module '@material-ui/core/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    mono: true;
  }
}
