import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { styled } from '@material-ui/core/styles';

const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const CenteredLoader = (): JSX.Element => (
  <Wrapper>
    <CircularProgress />
  </Wrapper>
);

export default CenteredLoader;
