import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RootState } from '../../store';

function usePrevious(value: unknown | undefined) {
  const ref = useRef<unknown | undefined>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

interface AuthGuardProps {
  children: React.ReactNodeArray | React.ReactNode;
}

const AuthGuard = function AuthGuard({ children }: AuthGuardProps): JSX.Element {
  const jwt = useSelector((state: RootState) => state.auth.jwt);
  const previousJwt = usePrevious(jwt);

  // When the JWT changes from defined to null -> redirect to the auth page
  if (previousJwt && !jwt) {
    window.localStorage.removeItem('haka_jwt');

    return <Redirect to="/auth" />;
  }

  if (jwt) {
    return <>{children}</>;
  }

  return <Redirect to="/auth" />;
};

export default AuthGuard;
