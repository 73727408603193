import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import { styled } from '@material-ui/core/styles';

const Root = styled('div')(({ theme }) => ({
  margin: theme.spacing(1),
  position: 'relative',
}));

const ButtonProgress = styled(CircularProgress)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
}) as typeof CircularProgress;

interface LoaderButtonProps extends ButtonProps {
  loading: boolean;
  color: CircularProgressProps['color'];
}

export default function LoaderButton(props: LoaderButtonProps): JSX.Element {
  const { loading, color, ...otherProps } = props;

  return (
    <Root>
      <Button {...otherProps} color={color} disabled={props.disabled || loading} />
      {loading && <ButtonProgress color={color || 'primary'} size={24} />}
    </Root>
  );
}
