import React from 'react';
import Card from '@material-ui/core/Card';
import { styled } from '@material-ui/core/styles';

import CenteredLayout from '../Layouts/CenteredLayout';

const MediaContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 5),
  background: `linear-gradient(135deg, rgba(19,140,254,1) 0%, rgba(0,232,255,1) 100%)`,
  display: 'flex',
  alignItems: 'center',
  height: '120px',
  width: '100%',
}));

const Media = styled('div')(({ theme }) => ({
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  width: theme.spacing(10),
  height: theme.spacing(10),
}));

const AppName = styled('div')({
  textAlign: 'center',
  textTransform: 'uppercase',
  fontSize: '2rem',
  color: '#fff',
  flex: '1 1 auto',
});

const CardContent = styled('div')(({ theme }) => ({
  borderColor: theme.palette.grey[300],
  borderWidth: 1,
  borderStyle: 'solid',
  borderTop: 0,
  overflow: 'hidden',
  borderBottomLeftRadius: theme.shape.borderRadius,
  borderBottomRightRadius: theme.shape.borderRadius,
}));

const CenteredMedia = ({ imageSrc }: { imageSrc: string | undefined }) => {
  if (imageSrc) {
    return (
      <MediaContainer>
        <Media style={{ backgroundImage: `url(${imageSrc})` }} />
        <AppName>{'Haka'}</AppName>
      </MediaContainer>
    );
  }
  return null;
};

const AuthCard = styled(Card)(({ theme }) => ({
  border: 0,
  overflow: 'hidden',
  [theme.breakpoints.up('sm')]: {
    minWidth: '400px',
  },
  [theme.breakpoints.down('xs')]: {
    width: '100%',
  },
})) as typeof Card;

interface AuthCardLayoutProps {
  children: React.ReactNode;
  imageSrc?: string | undefined;
  alert?: React.ReactNode | undefined;
}

const AuthCardLayout = (props: AuthCardLayoutProps): JSX.Element => {
  const { children, imageSrc, alert } = props;
  return (
    <CenteredLayout>
      <AuthCard>
        <CenteredMedia imageSrc={imageSrc} />
        {alert}
        <CardContent>{children}</CardContent>
      </AuthCard>
    </CenteredLayout>
  );
};

export default AuthCardLayout;
