import React from 'react';
import { styled } from '@material-ui/core/styles';

import { AppMenu } from '../Menu';

const Layout = styled('div')({
  minHeight: '100vh',
  maxHeight: '100vh',
  display: 'flex',
});

const Content = styled('div')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  overflow: 'auto',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(),
  },
  marginTop: theme.mixins.toolbar.height,
}));

const AppLayout = (props: { children: React.ReactNode | React.ReactNodeArray }): JSX.Element => (
  <Layout>
    <AppMenu />
    <Content>{props.children}</Content>
  </Layout>
);

export default AppLayout;
