import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Axios from 'axios';
import AuthAPI from '../api/Auth';

import { AuthInfo } from '../types';
import {
  mutateRequestState,
  mutateSuccessState,
  RequestFailureAction,
  mutateErrorState,
  requestState,
} from './toolkitUtils';
import { AppThunk } from '.';

const initialState = {
  jwt: null as null | string,
  authInfo: {
    first_name: undefined,
    last_name: undefined,
  } as AuthInfo,
  requests: {
    checkToken: requestState(),
    readAuthInfo: requestState(),
  },
};

export type AuthState = typeof initialState;

interface CheckTokenSuccessAction {
  jwt: string;
}

interface ReadAuthInfoSuccessAction {
  authInfo: AuthState['authInfo'];
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    checkTokenRequest(state) {
      mutateRequestState(state.requests.checkToken);
    },
    checkTokenSuccess(state, action: PayloadAction<CheckTokenSuccessAction>) {
      const { jwt } = action.payload;
      state.jwt = jwt;
      mutateSuccessState(state.requests.checkToken);
    },
    checkTokenFailure(state, action: PayloadAction<RequestFailureAction>) {
      state.jwt = null;
      mutateErrorState(state.requests.checkToken, action.payload.error);
    },
    checkTokenNoJwt(state) {
      state.requests.checkToken.inProgress = false;
    },
    readAuthInfoRequest(state) {
      mutateRequestState(state.requests.readAuthInfo);
    },
    readAuthInfoSuccess(state, action: PayloadAction<ReadAuthInfoSuccessAction>) {
      const { authInfo } = action.payload;
      state.authInfo = authInfo;
      mutateSuccessState(state.requests.readAuthInfo);
    },
    readAuthInfoFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.readAuthInfo, action.payload.error);
    },
    setJwt(state, action: PayloadAction<CheckTokenSuccessAction>) {
      const { jwt } = action.payload;
      state.jwt = jwt;
    },
    logout(state) {
      state.jwt = null;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;

export const checkToken = (token?: string): AppThunk => async (dispatch) => {
  dispatch(authActions.checkTokenRequest());
  let jwt;

  try {
    if (token) {
      jwt = await AuthAPI.checkToken(token);
    } else {
      const localStorageJwt = localStorage.getItem('haka_jwt');
      const localStorageJwtExpire = Number(localStorage.getItem('haka_jwt_expire'));

      if (localStorageJwt) {
        if (localStorageJwtExpire >= Date.now()) {
          // JWT is valid, send it with each API call
          localStorage.setItem('haka_jwt', localStorageJwt);
          localStorage.setItem('haka_jwt_expire', `${Date.now() + 24 * 60 * 60 * 1000}`); // expires after 24 hours
          Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorageJwt}`;
          jwt = localStorageJwt;
        } else {
          // The auth token exists but it's expire, remove it from local storage
          localStorage.removeItem('haka_jwt');
          localStorage.removeItem('haka_jwt_expire');
        }
      }
    }
  } catch (err) {
    dispatch(authActions.checkTokenFailure(err.toString()));
    return;
  }

  if (jwt) {
    localStorage.setItem('haka_jwt', jwt);
    localStorage.setItem('haka_jwt_expire', `${Date.now() + 24 * 60 * 60 * 1000}`); // expires after 24 hours
    Axios.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;
  } else {
    dispatch(authActions.checkTokenNoJwt());
    return;
  }

  dispatch(authActions.checkTokenSuccess({ jwt }));
  return jwt;
};

export const readAuthInfo = (): AppThunk => async (dispatch) => {
  dispatch(authActions.readAuthInfoRequest());
  let authInfo;

  try {
    authInfo = await AuthAPI.readAuthInfo();
  } catch (err) {
    dispatch(authActions.readAuthInfoFailure(err.toString()));
    return;
  }

  dispatch(authActions.readAuthInfoSuccess({ authInfo }));
};

export const logout = (): AppThunk => async (dispatch) => {
  localStorage.removeItem('haka_jwt');
  localStorage.removeItem('haka_jwt_expire');
  Axios.defaults.headers.common['Authorization'] = null;
  dispatch(authActions.logout());
};
