import React from 'react';
import { useTranslation } from 'react-i18next';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { styled } from '@material-ui/core/styles';

import { AssessmentStatus } from '../types/assessmentStatus';

const StatusLabel = styled('span')(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: theme.typography.fontWeightMedium,
}));

const StatusLabelInProgress = styled(StatusLabel)({
  color: blue[500],
});

const StatusLabelNew = styled(StatusLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const StatusLabelClosed = styled(StatusLabel)({
  color: green[700],
});

const StatusLabelOverdue = styled(StatusLabel)({
  color: red[500],
});

interface AssessmentStatusLabelProps {
  value: AssessmentStatus;
}

const AssessmentStatusLabel = (props: AssessmentStatusLabelProps): JSX.Element | null => {
  const { value } = props;
  const { t } = useTranslation();
  const label = t(`${value}`);

  switch (value) {
    case AssessmentStatus.Draft:
      return <StatusLabelOverdue>{label}</StatusLabelOverdue>;
    case AssessmentStatus.Ready:
      return <StatusLabelNew>{label}</StatusLabelNew>;
    case AssessmentStatus.InProgress:
      return <StatusLabelInProgress>{label}</StatusLabelInProgress>;
    case AssessmentStatus.Closed:
      return <StatusLabelClosed>{label}</StatusLabelClosed>;
    case AssessmentStatus.Overdue:
      return <StatusLabelOverdue>{label}</StatusLabelOverdue>;
  }

  return null;
  // return <span className={classNames.join(' ')}>{t(value)}</span>;
};

export default AssessmentStatusLabel;
