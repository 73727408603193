import { useRef, useEffect } from 'react';

/*
  Like useEffect, but skips the first callback invocation.
*/
export function useUpdateEffect(fn: () => void, inputs: Array<unknown>): void {
  const firstRunRef = useRef(false);

  useEffect(() => {
    if (firstRunRef.current) {
      return fn();
    } else {
      firstRunRef.current = true;
    }
  }, inputs); // eslint-disable-line
}
