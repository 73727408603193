import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';

import moment from 'moment';
import 'moment/locale/it';

import en from './en';
import it from './it';

export default function i18n(): void {
  i18next.use(initReactI18next).init({
    fallbackLng: 'it',
    lng: 'it',
    interpolation: {
      escapeValue: false, // not needed for react
    },
    resources: { it, en },
  });
  moment.locale('it');
}
