import React, { Suspense } from 'react';
import { styled } from '@material-ui/core/styles';
import { HelpComponent } from '../../brand';
import { Assessment } from '../../types';

const Root = styled('div')({
  '& p:first-child': {
    marginTop: 0,
  },
  '& li': {
    display: 'flex',
  },
});

interface AssessmentShortHelpProps {
  assessment: Assessment;
}

export default function AssessmentShortHelp(props: AssessmentShortHelpProps): JSX.Element {
  return (
    <Root>
      <Suspense fallback={null}>
        <HelpComponent context={{ assessment: props.assessment }} />
      </Suspense>
    </Root>
  );
}
