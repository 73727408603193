import React from 'react';
import Rating from '../../components/helpTexts/Rating';

export default function AssessmentHelp(): JSX.Element {
  return (
    <div>
      <p>Nel valutare ogni singolo aspetto legato alle competenze vi chiediamo di attenervi a queste indicazioni:</p>
      <ol>
        <li>
          <Rating value={0} />
          {'NON IN LINEA con le aspettative/richieste'}
        </li>
        <li>
          <Rating value={1} />
          {'PARZIALMENTE in linea con le aspettative/richieste'}
        </li>
        <li>
          <Rating value={2} />
          {'IN LINEA con le aspettative/richieste'}
        </li>
        <li>
          <Rating value={3} />
          {'TALVOLTA sopra le aspettative/richieste'}
        </li>
        <li>
          <Rating value={4} />
          {'COSTANTEMENTE sopra le aspettative/richieste'}
        </li>
      </ol>
    </div>
  );
}
