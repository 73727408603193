import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function GDPRModal(): JSX.Element {
  const [open, setOpen] = React.useState(!(window.localStorage.getItem('haka_gdpr_agreement') === 'true' || false));

  const handleClose = () => {
    window.localStorage.setItem('haka_gdpr_agreement', 'true');
    setOpen(false);
  };

  const descriptionElementRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (open) {
      descriptionElementRef?.current?.focus();
    }
  }, [open]);

  // FIXME: real company name
  const company = 'San Marco S.p.A.';

  return (
    <div>
      <Dialog
        open={open}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{'Informativa privacy GDPR'}</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1} component="div">
            <p>
              {`Ai sensi del Regolamento Ue 2016/679 GDPR (General Data Protection Regulation) (di seguito: GDPR) la ${company} (di seguito : il Titolare), nella qualità di titolare del trattamento, La informa che i suoi dati personali verranno trattati al fine di valutare e selezionare personale idoneo a beneficiare di attività formative svolte dal Titolare stesso ovvero da terzi ( di seguito: il Servizio) Il conferimento dei dati personali è facoltativo; tuttavia un suo eventuale rifiuto non permetterà al Titolare di poter effettuare il Servizio.`}
            </p>
            <p>
              {`Il trattamento dei Suoi dati personali avverrà anche attraverso strumenti automatizzati atti a memorizzarli, gestirli e trasmetterli, nonché mantenerli in ambienti di cui è controllato l’accesso e in ogni caso, mediante strumenti idonei a garantirne la sicurezza e la riservatezza. I Suoi dati personali verranno conservati per un periodo non superiore a 36 mesi, al termine del quale verranno distrutti. I Suoi dati personali potranno essere trattati anche da responsabili esterni e/o da incaricati del Titolare, e non saranno comunicati a terzi in Italia ed all’estero, eccezion fatta per le società collegate, controllanti o controllate dal Titolare, alle altre società del gruppo ${company} in Italia ed all’estero.`}
            </p>
            <p>
              {
                'La informiamo inoltre che potrà esercitare i diritti previsti dall’art. 17 del GDPR, ed in particolare ottenere la conferma dell’esistenza o meno dei loro Dati Personali trattati, conoscerne il contenuto, l’origine, e le modalità di trattamento, verificarne l’esattezza, chiederne l’integrazione, l’aggiornamento, la rettificazione o (nei casi previsti) la cancellazione. Tali diritti potranno essere esercitati mediante richiesta scritta da inoltrare al Titolare presso la sede di quest’ultimo.'
              }
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Acconsento
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
