import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';

import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { styled } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Typography from '@material-ui/core/Typography';
import { RootState } from '../../store';
import { CampaignAssessment } from '../../types';
import AssessmentsTableRow from './AssessmentsTableRow';

const Root = styled('div')({
  width: '100%',
});

const CampaignTable = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const CampaignPaper = styled(Paper)({
  width: '100%',
}) as typeof Paper;

const PollName = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(5),
}));

const AssessmentsTableHeader = styled(TableRow)(({ theme }) => ({
  '&>:first-child': {
    paddingLeft: theme.spacing(6),
  },
}));

const CampaignTableHead = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 3),
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));

const CampaignName = styled(Typography)({
  fontSize: '1rem',
}) as typeof Typography;

interface AssessmentsTableProps {
  className?: string | undefined;
  onAssessmentClick: (assessmentId: CampaignAssessment['id']) => void;
}

function AssessmentsTable(props: AssessmentsTableProps): JSX.Element {
  const { className, onAssessmentClick } = props;
  const { t } = useTranslation();

  const { polls, byId: campaigns } = useSelector((state: RootState) => state.campaigns);

  const pollSectionElements = [];

  for (const [pollId, poll] of Object.entries(polls)) {
    const campaignTableElements = [];

    for (const [campaignId, campaign] of Object.entries(campaigns)) {
      if (campaign.poll.id === pollId) {
        const assessmentRows = campaign.assessments.map((assessment) => {
          return (
            <AssessmentsTableRow key={assessment.id} assessment={assessment} onAssessmentClick={onAssessmentClick} />
          );
        });

        let expiresOnChip = null;

        if (moment(campaign.end_at).isAfter(moment())) {
          expiresOnChip = <Chip label={`${t('assessmentsPage:expiresOn')} ${moment(campaign.end_at).format('L')}`} />;
        } else {
          expiresOnChip = <Chip label={`${t('assessmentsPage:expiredOn')} ${moment(campaign.end_at).format('L')}`} />;
        }

        campaignTableElements.push(
          <CampaignTable key={campaignId}>
            <CampaignPaper>
              <CampaignTableHead>
                <CampaignName variant="h6">{`${campaign.name}`}</CampaignName>
                {expiresOnChip}
              </CampaignTableHead>
              <Table>
                <TableHead>
                  <AssessmentsTableHeader>
                    <TableCell>{t('assessmentsPage:tablePollNameColumnTitle')}</TableCell>
                    <TableCell>{t('assessmentsPage:tableStatusColumnTitle')}</TableCell>
                  </AssessmentsTableHeader>
                </TableHead>
                <TableBody>{assessmentRows}</TableBody>
              </Table>
            </CampaignPaper>
          </CampaignTable>
        );
      }
    }

    if (campaignTableElements.length > 0) {
      pollSectionElements.push(
        <Fragment key={pollId}>
          <PollName variant="h6" gutterBottom>
            {`${t(poll.name)}`}
          </PollName>
          {campaignTableElements}
        </Fragment>
      );
    }
  }

  return <Root className={className}>{pollSectionElements}</Root>;
}

export default AssessmentsTable;
