import Axios from 'axios';
import Store from '../store';
import { logout } from '../store/auth';
const API_URL = process.env.REACT_APP_API;

/**
 * Sets up the base URL to use in all API calls
 */
const setupBaseUrl = function setupBaseUrl() {
  // Take it from the env
  Axios.defaults.baseURL = API_URL;

  Axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        Store.dispatch(logout());
      }

      return Promise.reject(error);
    }
  );
};

export function setup(): void {
  setupBaseUrl();
}
