import React from 'react';
import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';
import IconButton from '@material-ui/core/IconButton';
import Snackbar, { SnackbarProps } from '@material-ui/core/Snackbar';
import SnackbarContent, { SnackbarContentProps } from '@material-ui/core/SnackbarContent';
import { styled } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

interface SnackbarContentWithVatiantProps extends SnackbarContentProps {
  snackbarVariant: SnackbarVariant;
}

const SnackbarContentWithVatiant = (props: SnackbarContentWithVatiantProps) => {
  const { snackbarVariant: _snackbarVariant, ...otherProps } = props;
  return <SnackbarContent {...otherProps} />;
};

const StyledSnackbarContent = styled(SnackbarContentWithVatiant)(({ theme, snackbarVariant }) => {
  switch (snackbarVariant) {
    case 'success':
      return { backgroundColor: green[600] };
    case 'error':
      return { backgroundColor: theme.palette.error.dark };
    case 'info':
      return { backgroundColor: theme.palette.primary.dark };
    case 'warning':
      return { backgroundColor: amber[700] };
    default:
      return {};
  }
}) as typeof SnackbarContentWithVatiant;

type SnackbarVariant = 'success' | 'error' | 'info' | 'warning' | null;

interface HakaSnackbarProps extends SnackbarProps {
  className?: string | undefined;
  action?: React.ReactNodeArray | undefined;
  // open: any;
  // message: any;
  onClose: () => void;
  hideCloseButton?: boolean | undefined;
  variant: SnackbarVariant;
}

/**
 * An attempt to make a standard snackbar for the entire project.
 * Set `loading` true to show the spinner, it is hidden otherwise.
 * The Snackbar will inherit the other HakaSnackbar props.
 */
const HakaSnackbar = function HakaSnackbar(props: HakaSnackbarProps): JSX.Element {
  const { action, open, message, hideCloseButton, onClose, variant, ...otherProps } = props;

  let closeIcon = null;

  if (!hideCloseButton) {
    closeIcon = (
      <IconButton key="close" aria-label="Close" color="inherit" onClick={() => onClose()}>
        <CloseIcon />
      </IconButton>
    );
  }

  const actionButtons = action || [];

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      {...otherProps}
      autoHideDuration={6000}
      open={open}
      onClose={onClose}
    >
      <StyledSnackbarContent
        snackbarVariant={variant}
        aria-describedby="client-snackbar"
        message={<span id="message-id">{message}</span>}
        action={[...actionButtons, closeIcon]}
      />
    </Snackbar>
  );
};

export default HakaSnackbar;
