import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface CloseModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export default function CloseModal(props: CloseModalProps): JSX.Element {
  const { open, onConfirm, onClose } = props;
  const { t } = useTranslation(['assessmentPage']);

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('assessmentPage:closeModal:title')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t('assessmentPage:closeModal:text')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t('assessmentPage:closeModal:close')}
          </Button>
          <Button onClick={onConfirm} color="primary" autoFocus>
            {t('assessmentPage:closeModal:confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
