import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { makeStyles, styled } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { RootState } from '../../store';
import { assessmentStatus } from '../../store/assessments';
import { Assessment, Campaign, Question } from '../../types';
import { canEditAssessment } from '../../utils';
import LoaderButton from '../LoaderButton';
import AreaStatusIcon from './AreaStatusIcon';
import QuestionComponent from './Question';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'calc(100vh - 375px)',
    display: 'flex',
    flexDirection: 'column',
  },
  indicationsSheet: {
    padding: theme.spacing(2),
  },
  questions: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 'auto',
    marginBottom: theme.spacing(3),
  },
  nextButton: {
    marginLeft: 'auto',
  },
  thanksForCompiling: {
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  completedAt: {
    padding: `${theme.spacing()}px 0`,
    textAlign: 'center',
  },
  backToAssessments: {
    marginTop: `${theme.spacing(4)}px`,
  },
  bottomGrid: {
    flexDirection: 'column',
    margin: 0,
    marginTop: `${theme.spacing(2)}px`,
    width: '100%',
  },
  bottomGridButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    marginTop: theme.spacing(2),
  },
  bottomGridButton: {
    display: 'flex',
    marginLeft: theme.spacing(),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginBottom: theme.spacing(),
      flexBasis: '100%',
    },
  },
  finishButton: {
    width: '100%',
  },
  stepper: {
    padding: 0,
  },
  subarea: {
    margin: theme.spacing(0, 0, 4),
  },
}));

const AreaTitle = styled('div')(({ theme }) => ({
  ...theme.typography.h5,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(2),
}));

interface AreasStepperProps {
  activeStep: number;
  assessment: Assessment;
  campaign: Campaign;
  comments: Assessment['comments'];
  onAnswerChange: (questionId: Question['id'], value: number) => void;
  onCommentChange: (comment: Partial<Assessment['comments']>) => void;
  onBackToAssessmentsClick: () => void;
  onClose: () => void;
  onFinish: () => void;
  onSave: () => void;
  onChangeActiveStep: (step: number) => void;
}

function AreasStepper(props: AreasStepperProps): JSX.Element {
  const [indications, setIndications] = useState('');
  const areaTitleRef = useRef<HTMLDivElement>(null);

  const classes = useStyles();
  const { t } = useTranslation();

  const indicationsError = indications.length > 500 ? t('assessmentPage:textMax500Chars') : null;

  const textFieldsHaveErrors = Boolean(indicationsError);

  const {
    activeStep,
    // areas,
    assessment,
    campaign,
    comments,
    // questions,
    // subareas,
    // update,
    onAnswerChange,
    onCommentChange,
    onBackToAssessmentsClick,
    onClose,
    onFinish,
    onSave,
    onChangeActiveStep,
  } = props;

  const status = useSelector((state: RootState) => assessmentStatus(state));
  const storeAreas = useSelector((state: RootState) => state.assessments.areas);
  const area = useMemo(() => {
    const areaId = assessment.areas[activeStep];
    if (areaId) {
      return storeAreas[areaId];
    }
    return undefined;
  }, [activeStep, assessment.areas, storeAreas]);

  const storeSubareas = useSelector((state: RootState) => state.assessments.subareas);
  const subareas = useMemo(() => {
    if (!area) {
      return [];
    }

    return area.subareas.map((subareaId) => storeSubareas[subareaId]);
  }, [area, storeSubareas]);

  const update = useSelector((state: RootState) => state.assessments.requests.update);

  const disabled = !canEditAssessment(assessment.status);

  useEffect(() => {
    if (comments) {
      if (comments['indications']) {
        setIndications(comments['indications']);
      }
    }
  }, [comments]);

  const setActiveStepAndGoUp = (step: number) => {
    onChangeActiveStep(step);
    areaTitleRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const completedPercentage = Math.min(100, (status.answered / status.total) * 100);

  let finishButton = null;
  let activeStepElement = null;

  if (activeStep < assessment.areas.length) {
    let previousButton = null;
    let nextButton = null;

    let questionElements: React.ReactNodeArray = [];

    if (area?.questions) {
      questionElements = area.questions.map((questionId) => {
        return (
          <QuestionComponent
            key={questionId}
            questionId={questionId}
            evaluation_scale={campaign.evaluation_scale}
            onChange={(value) => onAnswerChange(questionId, value)}
            disabled={disabled}
          />
        );
      });
    }

    let subareaElements: React.ReactNodeArray = [];

    if (subareas) {
      subareaElements = subareas.map((subarea) => {
        if (!subarea) {
          return null;
        }

        const subareaQuestionElements = subarea.questions.map((questionId) => {
          return (
            <QuestionComponent
              key={questionId}
              questionId={questionId}
              evaluation_scale={campaign.evaluation_scale}
              onChange={(value) => onAnswerChange(questionId, value)}
              disabled={disabled}
            />
          );
        });

        return (
          <div key={subarea.id} className={classes.subarea}>
            <Typography variant="h6">{subarea.name}</Typography>
            <Typography variant="body2" gutterBottom>
              {subarea.description}
            </Typography>
            {subareaQuestionElements}
          </div>
        );
      });
    }

    if (activeStep > 0) {
      previousButton = (
        <Button color="primary" disabled={activeStep === 0} onClick={() => setActiveStepAndGoUp(activeStep - 1)}>
          <NavigateBeforeIcon />
          {t('common:back')}
        </Button>
      );
    }

    if (activeStep < assessment.areas.length) {
      nextButton = (
        <Button color="primary" onClick={() => setActiveStepAndGoUp(activeStep + 1)} className={classes.nextButton}>
          {t('common:next')}
          <NavigateNextIcon />
        </Button>
      );
    }

    activeStepElement = (
      <>
        <AreaTitle ref={areaTitleRef}>
          <Box>
            <AreaStatusIcon area={area} />
          </Box>
          <Box pb={1} ml={2}>
            {area?.name}
          </Box>
        </AreaTitle>

        <Box mt={2} mb={2}>
          {questionElements}
          {subareaElements}
        </Box>

        <Grid item xs={12} className={classes.actionsContainer}>
          {previousButton}
          {nextButton}
        </Grid>
      </>
    );
  } else {
    // The Indications step
    activeStepElement = (
      <>
        <AreaTitle ref={areaTitleRef}>
          <Box>
            <AreaStatusIcon />
          </Box>
          <Box pb={1} ml={2}>
            {t('assessmentPage:indications')}
          </Box>
        </AreaTitle>
        <Box mt={2} mb={2}>
          <Paper className={classes.indicationsSheet} elevation={1}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  id="standard-multiline-static"
                  label={t('assessmentPage:indications')}
                  multiline
                  rows="8"
                  variant="outlined"
                  fullWidth={true}
                  value={indications}
                  onChange={(event) => setIndications(event.target.value)}
                  onBlur={() => onCommentChange({ indications })}
                  error={Boolean(indicationsError)}
                  helperText={indicationsError}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
        <Grid item xs={12} className={classes.actionsContainer}>
          <Button color="primary" disabled={activeStep === 0} onClick={() => setActiveStepAndGoUp(activeStep - 1)}>
            <NavigateBeforeIcon />
            {t('common:back')}
          </Button>
        </Grid>
      </>
    );
  }

  if (!canEditAssessment(assessment.status)) {
    finishButton = (
      <Tooltip
        disableFocusListener
        title={`${t('assessmentPage:assessmentStatusNotValid', { status: t(`${assessment.status}`) })}`}
      >
        <span className={classes.bottomGridButton}>
          <Button variant="outlined" size="large" color="primary" className={classes.finishButton} disabled>
            {t('common:finish')}
          </Button>
        </span>
      </Tooltip>
    );
  } else if (status.answered !== status.total) {
    finishButton = (
      <Tooltip disableFocusListener title={`${t('assessmentPage:answerAllQuestions')}`}>
        <span className={classes.bottomGridButton}>
          <Button variant="outlined" size="large" color="primary" className={classes.finishButton} disabled>
            {t('common:finish')}
          </Button>
        </span>
      </Tooltip>
    );
  } else if (textFieldsHaveErrors) {
    finishButton = (
      <Tooltip disableFocusListener title={`${t('assessmentPage:textFieldsHaveErrors')}`}>
        <span className={classes.bottomGridButton}>
          <Button variant="outlined" size="large" color="primary" className={classes.finishButton} disabled>
            {t('common:finish')}
          </Button>
        </span>
      </Tooltip>
    );
  } else {
    finishButton = (
      <Button className={classes.bottomGridButton} variant="outlined" size="large" color="primary" onMouseUp={onFinish}>
        {t('common:finish')}
      </Button>
    );
  }

  const completedAt = (
    <div className={classes.completedAt}>
      <Typography variant="caption">
        {t('assessmentPage:completedPercentage', { percentage: Math.round(completedPercentage * 10) / 10 })}
      </Typography>
      <LinearProgress variant="determinate" value={completedPercentage} />
    </div>
  );

  return (
    <div className={classes.root}>
      {activeStepElement}
      {activeStep === assessment.areas.length + 1 && (
        <Typography variant="h6" className={classes.thanksForCompiling}>
          <div>{t('assessmentPage:thanksForCompiling')}</div>
          <Button color="primary" className={classes.backToAssessments} onClick={onBackToAssessmentsClick}>
            {t('assessmentPage:backToAssessments')}
          </Button>
        </Typography>
      )}
      <Grid className={classes.bottomGrid} container spacing={3}>
        <Grid item xs={12}>
          {completedAt}
        </Grid>
        <Grid item xs={12} className={classes.bottomGridButtons}>
          <Button className={classes.bottomGridButton} size="large" onClick={onClose}>
            {t('common:close')}
          </Button>
          <LoaderButton
            className={classes.bottomGridButton}
            size="large"
            variant="outlined"
            color="primary"
            onMouseUp={onSave}
            loading={update.inProgress}
            disabled={disabled}
          >
            {t('common:save')}
          </LoaderButton>
          {finishButton}
        </Grid>
      </Grid>
    </div>
  );
}

export default AreasStepper;
