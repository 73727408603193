import React from 'react';
import { useTranslation } from 'react-i18next';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import { styled } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import { Person } from '../../types';

const BranchChip = styled(Chip)(({ theme }) => ({
  marginTop: theme.spacing(),
  marginRight: theme.spacing(),
}));

interface UserCardProps {
  user: Person;
}

function UserCard(props: UserCardProps): JSX.Element {
  const { user } = props;
  const { t } = useTranslation(['position', 'branch']);
  const branchesPills = user.branches.map((branch) => <BranchChip key={branch.name} label={t(branch.name)} />);

  return (
    <div>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2">
            {user.first_name} {user.last_name}
          </Typography>
          <Typography color="textSecondary" gutterBottom>
            {t(user.position.name)}
          </Typography>
          {branchesPills}
        </CardContent>
      </Card>
    </div>
  );
}

export default UserCard;
